import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';


export default class RightWrong extends Plugin {

  init() {
    const editor = this.editor;

    editor.ui.componentFactory.add('is-correct', () => {
      const button = new ButtonView();
      button.set( {
        label: '✓',
        withText: true
      });
      button.on( 'execute', () => {
        editor.model.change( writer => {
          const viewFragment = editor.data.processor.toView('<strong class="text-green">✓</strong>');
          const modelFragment = editor.data.toModel( viewFragment );
          editor.model.insertContent(modelFragment);
        });

      });
      return button;
    } );

    editor.ui.componentFactory.add('is-wrong', () => {
      const button = new ButtonView();
      button.set( {
        label: '✘',
        withText: true
      });
      button.on( 'execute', () => {
        editor.model.change( writer => {
          const viewFragment = editor.data.processor.toView('<span class="text-red">✘</span>');
          const modelFragment = editor.data.toModel( viewFragment );
          editor.model.insertContent(modelFragment);
        });

      });
      return button;
    } );

  }
}