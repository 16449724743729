export default function allowFontClass(editor, property, colors) {
  for (const i in colors) {
    editor.conversion.for('upcast').elementToAttribute({
      view: {
        name: 'span',
        classes: colors[i].class
      },
      model: {
        key: property,
        value: colors[i].color,
      },
      converterPriority: 'high'
    });

    editor.conversion.for('downcast').attributeToElement({
      model: {
        key: property,
        value: colors[i].class,
      },
      view: (attributeValue, {writer}) => {
        const spanElement = writer.createAttributeElement('span', {
          class: getClassColorName(colors, attributeValue)
        }, {priority: 5});
        //writer.setCustomProperty('fontClass', true, spanElement);
        return spanElement;
      },
      converterPriority: 'high'
    });
  }
}

function getClassColorName(colors, attributeValue) {
  for (const i in colors) {
    if (colors[i].color === attributeValue) {
      return colors[i].class;
    }
  }

  return;
}