import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';


export default class CopySource extends Plugin {

  init() {
    const editor = this.editor;

    editor.ui.componentFactory.add( 'copy-source', () => {
      const button = new ButtonView();

      button.set( {
        label: 'Copy HTML',
        withText: true
      } );

      // Button-related code from the previous example
      // ...

      // Execute a callback function when the button is clicked.
      button.on( 'execute', () => {

        navigator.clipboard.writeText(editor.getData()).then(function() {
          console.log('Async: Copying to clipboard was successful!', editor.getData());
        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      });

      return button;
    } );
  }
}