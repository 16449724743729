const TEXT_COLORS = [
  { label: 'Blue', color: '#23ACF9', class: 'text-blue' },
  { label: 'Red', color: '#FF7B69', class: 'text-red'  },
  { label: 'Yellow', color: '#F9D923', class: 'text-yellow' },
  { label: 'Green', color: '#67C84A', class: 'text-green'  },
];

const BACKGROUND_COLORS = [
  { label: 'Blue', color: '#BAE6FF', class: 'bg-blue', colorDark: "#00324F" },
  { label: 'Red', color: '#FFEDEA', class: 'bg-red', colorDark: "#420002"  },
  { label: 'Yellow', color: '#FFF1BA', class: 'bg-yellow', colorDark: "#45451B" },
  { label: 'Green', color: '#F7FFEE', class: 'bg-green', colorDark: "#003A02" },
];

const CARD_COLORS = [
  { class: "card-blue", background: "#BAE6FF", backgroundDark: "#00324F", lineColor: "#23ACF9"},
  { class: "card-red", background: "#FFEDEA", backgroundDark: "#420002", lineColor: "#FF7B69"},
  { class: "card-yellow", background: "#FFF1BA", backgroundDark: "#45451B", lineColor: "#F9D923"},
  { class: "card-green", background: "#F7FFEE", backgroundDark: "#003A02", lineColor: "#67C84A"},
]

module.exports = {
  TEXT_COLORS,
  BACKGROUND_COLORS,
  CARD_COLORS
}